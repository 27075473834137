import type { Agegroup } from "~/ts/interfaces/Agegroup";
import type { Department } from "~/ts/interfaces/Department";
import type { Document } from "~/ts/interfaces/Document";
import type { FAQuestion } from "~/ts/interfaces/FAQuestion";
import type { Gallery } from "~/ts/interfaces/Gallery";
import type { StrapiDataCollection } from "~/ts/interfaces/strapi/StrapiData";

export class ApiUtil {

    static async getAgeGroups(populate = false): Promise<StrapiDataCollection<Agegroup>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/agegroups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as StrapiDataCollection<Agegroup>;
    }

    static async getAgeGroup(id: string, populate = false): Promise<Agegroup> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/agegroups?filters[id][$eq]=${id}&populate[leaders][populate]=image&populate[activities][populate]=*&populate[group_photo][populate]=*&populate[quarterly_plans][populate]=*&[populate][registration_form][populate]=*&populate[Contact][populate]=*`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return (data.value as StrapiDataCollection<Agegroup>).data[0] as Agegroup;
    }

    static async getDocuments(populate = false): Promise<Document[]> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/documents`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                // populate: `${populate ? '*' : ''}`,
                'populate[department]': '*',
                'populate[agegroup]': '*',

            },
        });
        return data.value as Document[];
    }

    static async getGalleries(populate = false): Promise<Gallery[]> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/galleries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as Gallery[];
    }

    static async getGallery(id: string, populate = false): Promise<Gallery> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/galleries?filters[id][$eq]=${id}&populate[0]=images&populate[1]=activity`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return (data.value as StrapiDataCollection<Gallery>).data[0] as Gallery;
    }

    static async getFaq(populate = false): Promise<StrapiDataCollection<FAQuestion>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/faqs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as StrapiDataCollection<FAQuestion>;
    }

    static async getDepartments(populate = true): Promise<StrapiDataCollection<Department>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/departments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                // populate: `${populate ? '*' : ''}`,
                'populate[leaders][populate]': '*',
                'populate[Contact]': '*',
            },
        });
        return data.value as StrapiDataCollection<Department>;
    }
}